import React from 'react';

const FigmaPage: React.FC = () => {
  return (
    <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <iframe
        src ="https://embed.figma.com/proto/iSmkgttXxg3zN4yOvw2R2S/Interface-Client?page-id=15%3A757&node-id=1194-17107&p=f&viewport=709%2C1541%2C0.16&scaling=min-zoom&content-scaling=fixed&starting-point-node-id=1194%3A17107&hotspot-hints=0&embed-host=share&hide-ui=1"
        // src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FdRy9Lje8rFHitPCTonzdUr%2FTPRM%3Fpage-id%3D1%253A92207%26node-id%3D46-61943%26viewport%3D185%252C-2425%252C0.23%26t%3DFC6xyyIl1H35dnjd-1%26scaling%3Dmin-zoom%26starting-point-node-id%3D46%253A61943%26content-scaling%3Dfixed%26hotspot-hints%3D0&hide-ui=1"
        title="Figma"
        width="100%"
        height="100%"
        style={{ border: 'none' }}
        allowFullScreen
      />
    </div>
  );
};

export default FigmaPage;
